<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col cols="12">
                <base-card>
                    <v-card-title>
                        <v-row no-gutters>
                            <v-col :cols="this.$vuetify.breakpoint.xsOnly ? '8' : '4'" style="align-self: center; display: flex;">
                                <v-avatar class="ml-1" :size="$vuetify.breakpoint.xsOnly ? 40 : 60"><v-img contain :src="`/assets/gaa/teams/clubs/${leagueLogo}.jpg`"></v-img></v-avatar>
                                <v-text-field :value="leagueTitle" solo flat readonly hide-details class="mx-1 text-h5">
                                    <template v-slot:append v-if="showLeaveIcon">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" @click="leaveLeague"
                                                    small>mdi-minus-circle-outline</v-icon>
                                            </template>
                                            <span>Exit league</span>
                                        </v-tooltip>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="1" class="d-flex" style="align-items: center;"><span class="text-caption font-weight-bold">{{
                                    leagueCode }}</span></v-col>
                            <v-spacer v-if="!this.$vuetify.breakpoint.xsOnly" />
                            <v-col cols="2" v-if="!this.$vuetify.breakpoint.xsOnly">
                                <v-text-field v-model="search" label="Search" clearable hide-details
                                    class="mx-1"></v-text-field>
                            </v-col>
                            <v-col cols="2" v-if="!this.$vuetify.breakpoint.xsOnly">
                                <v-select id="matchdaySelect" :items="previousGameweeks" label="Matchday"
                                    @change="updateGameweek" v-model="selectedGameweek" />
                            </v-col>
                            <v-col v-if="this.$vuetify.breakpoint.xsOnly" cols="3">
                                <v-btn class="ma-2" color="primary" @click="expandedTable = !expandedTable">
                                    <v-icon>{{ tableViewIcon }}</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-data-table id="leagueTable" v-model="selected" :search="search" :headers="headers"
                        :items="getLeagueState" item-key="name" sort-by="position"
                        :mobile-breakpoint="expandedTable ? 'xs' : '0'" class="elevation-1 table-one"
                        :header-class="'table-headers-word-wrap'"
                        :class="this.$vuetify.breakpoint.smAndDown ? 'hide-header' : ''" :footer-props="{
                            itemsPerPageText: 'Display'
                        }">
                        <template v-slot:item.position="{ item }">
                            <b class="ml-4">{{ item.position == 0 ? '-' : item.position }}</b>
                        </template>
                        <template v-slot:item.positionChange="{ item }">
                            <v-chip :class="changeColor(
                                String(item.positionChange)
                            )
                                " small label class="px-2 secondary"> <v-icon class="white--text">{{
                                arrowDisplay(String(item.positionChange)) }}</v-icon>
                                {{ positionText(String(item.positionChange)) }}
                            </v-chip>
                        </template>
                        <template v-slot:item.weekPoints="{ item }">
                            <b>{{ item.weekpoints }}</b>
                        </template>
                        <template v-slot:item.points="{ item }">
                            <b>{{ item.points }}</b>
                        </template>
                        <template v-slot:item.displayName="{ item }">
                            <div class="d-flex align-center">
                                <p class="ma-0 font-weight-medium">
                                    {{ item.displayName }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <div class="d-flex">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="success" dark v-bind="attrs" v-on="on" icon>
                                            <v-icon>mdi-pencil-box-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="danger" dark v-bind="attrs" v-on="on" icon>
                                            <v-icon>mdi-trash-can-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </v-data-table>
                </base-card>
            </v-col>
            <v-dialog v-model="showConfirm" :max-width="$vuetify.breakpoint.xsOnly ? '70%' : '25%'">
                <v-card>
                    <v-card-title class="text-h5 success lighten-2">
                        <span>Exit League</span>
                        <v-spacer />
                        <v-icon>mdi-minus-circle-outline</v-icon>
                    </v-card-title>

                    <v-card-text class="mt-2">
                        Are you sure you wish to leave the league? <br />This action cannot be undone!
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn color="white" text @click="showConfirm = false">
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="error" text @click="leave()">
                            Leave
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: ['leagueId'],
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Leagues'
    },
    async mounted() {
        this.fetchParticipantsForLeague(this.$route.params.leagueId);
        let resp = await this.fetchLeagueState(this.$route.params.leagueId);
        this.loading = false;
        this.selectedGameweek = this.$store.getters.getCurrentGameweek - 1;
    },
    data() {
        return {
            search: '',
            selected: [],
            expandedTable: false,
            selectedGameweek: -1,
            showConfirm: false,
            loading: true
        }
    },
    computed: {
        ...mapGetters(["getLeagueState", "getCurrentGameweek", "getCurrentLeague", "loggedInUser", "loggedInUserId"]),
        leagueTitle() {
            return this.getCurrentLeague ? this.getCurrentLeague.league.name : 'Overall'
        },
        showLeaveIcon() {
            return this.getCurrentLeague && this.getCurrentLeague.league.owner != null
        },
        leagueLogo(){
            return this.getCurrentLeague && this.getCurrentLeague.league.club && this.getCurrentLeague.league.club.crestUrl ? this.getCurrentLeague.league.club.crestUrl : false
        },
        leagueCode() {
            return this.getCurrentLeague &&
                (this.getCurrentLeague.league.showCode
                    || this.getCurrentLeague.user.id == (this.loggedInUser && this.loggedInUser.userDetails.id))
                ? this.getCurrentLeague.league.code : ''
        },
        headers() {
            let headers = [
                { text: this.$vuetify.breakpoint.xsOnly ? 'Pos' : 'Position', value: 'position', sortable: false },
                {
                    text: 'Player',
                    value: 'displayName', sortable: false
                },
                // {text: 'Club', value: 'club'},
                { text: 'Week Points', value: 'weekPoints', align: 'center', sortable: false },
                { text: 'This Week', value: 'positionChange', align: 'center', sortable: false },
                { text: 'Overall Points', value: 'points', align: 'center', sortable: false }
            ]
            if (!this.$vuetify.breakpoint.smAndDown) {
                headers.splice(4, 0, { text: 'Weekly Average', value: 'average', align: 'center', sortable: false });
            }
            return headers;
        },
        tableViewIcon() {
            return this.expandedTable ? 'mdi-view-sequential' : 'mdi-view-agenda'
        },
        previousGameweeks() {
            let highestNumber = this.$store.getters.getCurrentGameweek;
            let totalWeeks = [];
            for (let i = 1; i <= highestNumber - 2; i++) {
                totalWeeks.push(i)
            }
            totalWeeks.push({ text: 'Current', value: this.$store.getters.getCurrentGameweek - 1 });
            return totalWeeks;
        }
    },
    methods: {
        ...mapActions(["fetchParticipantsForLeague", "fetchLeagueState", "fetchLeagueStateForGameweek", "exitLeague", "fetchPersonalLeagues"]),
        async leave() {
            let success = await this.exitLeague({ "userId": this.loggedInUserId, "leagueId": this.leagueId });
            if (success) {
                this.fetchPersonalLeagues({ userId: this.loggedInUser.userDetails.id, sport: this.$router.currentRoute.meta.sport.toUpperCase() });
                this.$router.push('/app/hurling/home')
            } else {
                this.showConfirm = false;
            }
        },
        changeColor(percent) {
            if (!this.ifValueExists(percent)) { return '' };
            if (percent.indexOf("-") > -1) {
                return 'danger white--text'
            } else if (percent == "0") {
                return 'primary white--text'
            } else {
                return 'success white--text'
            }
        },
        leaveLeague() {
            this.showConfirm = !this.showConfirm
        },
        arrowDisplay(change) {
            if (!this.ifValueExists(change)) { return '' };
            if (change.indexOf("-") > -1) {
                return 'mdi-triangle-small-down'
            } else if (change == "0") {
                return 'mdi-minus'
            } else {
                return 'mdi-triangle-small-up'
            }
        },
        positionText(change) {
            if (!this.ifValueExists(change)) { return '' };
            if (change.indexOf("-") > -1) {
                return change.substring(1);
            } else if (change == '0') {
                return '';
            } else {
                return change
            }
        },
        ifValueExists(value) {
            if (value !== null && value !== undefined) {
                return true;
            } else {
                return false;
            }
        },
        updateGameweek(val) {
            if (val == 'Current') {
                val = this.getCurrentGameweek;
            }
            this.fetchLeagueStateForGameweek({ "leagueId": this.$route.params.leagueId, "gameweek": val });
        }
    },
    watch: {
        getCurrentGameweek(val) {
            this.selectedGameweek = val - 1;
        },
        leagueId(newVal, oldVal) {
            this.fetchLeagueData(newVal);
        }
    }
}
</script>
<style lang="scss" scoped>
@media (max-width: 600px) {

    ::v-deep .v-data-table__wrapper table tbody tr td,
    ::v-deep .v-data-table__wrapper table tbody tr th,
    ::v-deep .v-data-table__wrapper table thead tr td,
    ::v-deep .v-data-table__wrapper table thead tr th,
    ::v-deep .v-data-table__wrapper table tfoot tr td,
    ::v-deep .v-data-table__wrapper table tfoot tr th {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
}

// ::v-deep .v-data-footer {
//     padding-top: 5px !important;
// }

::v-deep .v-data-table-header tr th span {
    word-wrap: normal;
}

.table-headers-word-wrap th {
    white-space: normal;
}

::v-deep .table-one {
    tbody {
        margin-bottom: 10px;
    }
}

::v-deep .table-one {
    thead.v-data-table-header-mobile {
        tr {
            th {
                height: 0;
            }
        }
    }
}

::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }

            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }

        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }

    tbody {
        tr {}
    }
}
</style>
