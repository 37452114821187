import ENDPOINTS from '@/api/endpoints/ktg-services.js'
import { service } from '@/api/baseClient.js'


export default {
    createTeam(teamData) {
        return service.post(ENDPOINTS.TEAM.CREATE, teamData)
    },
    sendEmail(emailData){
        return service.post(ENDPOINTS.ADMIN.EMAIL, emailData)
    },
    updateCompetition(competitionData) {
        return service.post(ENDPOINTS.COMPETITION.UPDATE, competitionData)
    },
    createFixture(fixtureData) {
        return service.post(ENDPOINTS.FIXTURE.CREATE, fixtureData)
    },
    endGameWeek(gameWeekData) {
        return service.post(ENDPOINTS.GAME.END_WEEK, gameWeekData)
    },
    startGameWeek(gameWeekData) {
        return service.post(ENDPOINTS.GAME.START_WEEK, gameWeekData)
    },
    confirmSelection(selectionData) {
        return service.post(ENDPOINTS.SELECTION.CONFIRM, selectionData)
    },
    fetchWinningMargins(sport) {
        return service.get(ENDPOINTS.GAME.WINNING_MARGINS, {
            params: {
                sport: sport,
            }
        });
    },
    fetchEmailRecipients() {
        return service.get(ENDPOINTS.GAME.EMAIL_RECIPIENTS);
    },
    fetchGameWeekSelection(userId, gameweek, competitionId) {
        return service.get(ENDPOINTS.GAME.PREDICTIONS, {
            params: {
                userId: userId,
                matchday: gameweek,
                competitionId: competitionId
            }
        });
    },
    fetchGameweekResultForUser(userId, sport, gameweek) {
        return service.get(ENDPOINTS.GAME.GAMEWEEK_RESULT, {
            params: {
                userId: userId,
                sport: sport,
                gameweek: gameweek
            }
        });
    },
    fetchPastSelections(userId, competitionId) {
        return service.get(ENDPOINTS.GAME.PAST_PREDICTIONS, {
            params: {
                userId: userId,
                competitionId: competitionId
            }
        });
    },
    fetchCompetitions() {
        return service.get(ENDPOINTS.COMPETITION.LIST);
    },
    fetchCompetitionStandings(competitionId, gameweek, name) {
        return service.get(ENDPOINTS.COMPETITION.STANDINGS, {
            params: {
                competitionId: competitionId,
                name: name,
                gameweek: gameweek
            }
        });
    },
    fetchClubs() {
        return service.get(ENDPOINTS.CLUB.LIST);
    },
    searchClubs(searchString) {
        return service.get(ENDPOINTS.CLUB.SEARCH, {
            params: {
                searchString: searchString
            }
        })
    },
    searchClubsByCounty(searchString, county) {
        return service.get(ENDPOINTS.CLUB.SEARCH_BY_COUNTY, {
            params: {
                searchString: searchString,
                county: county
            }
        })
    },
    updateClub(clubDetails) {
        return service.post(ENDPOINTS.CLUB.UPDATE_USERS_CLUB, clubDetails)
    },
    findRankingsBySport(sport) {
        if (sport == 'HURLING') {
            return service.get(ENDPOINTS.RANKINGS.HURLING)
        }
    },
    findRankingsByTeam(teamId) {
        return service.get(ENDPOINTS.RANKINGS.TEAM, {
            params: {
                teamId: teamId
            }
        })
    },
    fetchCompetitionStatus(sport) {
        return service.get(ENDPOINTS.COMPETITION.STATUS, {
            params: {
                sport: sport
            }
        });
    },
    fetchCompetitionPredictionsOpen(sport) {
        return service.get(ENDPOINTS.COMPETITION.PREDICTIONS_OPEN, {
            params: {
                sport: sport
            }
        })
    },
    setCompetitionPredictionOpen(open) {
        return service.post(ENDPOINTS.COMPETITION.PREDICTIONS_OPEN, null, {
            params: {
                open: open
            }
        })
    },
    fetchTeams(sport) {
        return service.get(ENDPOINTS.TEAM.LIST, {
            params: {
                sport: sport,
            },
        })
    },
    fetchTeamById(id) {
        return service.get(ENDPOINTS.TEAM.FETCH_BY_ID, {
            params: {
                id: id,
            },
        })
    },
    fetchTeamsByCompetition(competitionId) {
        return service.get(ENDPOINTS.TEAM.LIST_BY_COMPETITION, {
            params: {
                competitionId: competitionId,
            },
        })
    },
    fetchTeamsBySport(sport) {
        return service.get(ENDPOINTS.RESULT.LIST_TEAMS, {
            params: {
                sport: sport,
            },
        })
    },
    fetchFixtures(competitionId, snapshot) {
        return service.get(ENDPOINTS.FIXTURE.LIST, {
            params: {
                competitionId: competitionId,
                snapshot: snapshot
            },
        })
    },
    fetchFixturesForTeam(competitionId, teamId) {
        return service.get(ENDPOINTS.FIXTURE.LIST_FOR_TEAM, {
            params: {
                competitionId: competitionId,
                teamId: teamId
            },
        })
    },
    updateFixture(fixtureData) {
        return service.post(ENDPOINTS.FIXTURE.UPDATE, fixtureData)
    },
    fetchInPlayFixtures(competitionId) {
        return service.get(ENDPOINTS.FIXTURE.IN_PLAY, {
            params: {
                competitionId: competitionId
            },
        })
    },
    fetchCurrentFixtures(competitionId) {
        return service.get(ENDPOINTS.FIXTURE.CURRENT, {
            params: {
                competitionId: competitionId
            },
        })
    },
    fetchResultsByTeamAndCompetitionId(teamId, competitionId) {
        return service.get(ENDPOINTS.RESULT.OVERALL, {
            params: {
                teamId: teamId,
                competitionId: competitionId
            }
        })
    },
    fetchResultsByTeamsAndCompetitionId(team1Id, team2Id, competitionId) {
        return service.get(ENDPOINTS.RESULT.HEAD_TO_HEAD, {
            params: {
                team1Id: team1Id,
                team2Id: team2Id,
                competitionId: competitionId
            }
        })
    },
    fetchFinalsForTeam(teamId) {
        return service.get(ENDPOINTS.RESULT.FINALS, {
            params: {
                teamId: teamId
            }
        })
    },
    fetchFinalsForSport(sport) {
        return service.get(ENDPOINTS.RESULT.ALL_FINALS, {
            params: {
                sport: sport
            }
        })
    },
    fetchPersonalLeagues(userId, sport) {
        return service.get(ENDPOINTS.LEAGUE.PERSONALLIST, {
            params: {
                userId: userId,
                sport: sport
            },
        })
    },
    leaveLeague(data) {
        return service.post(ENDPOINTS.LEAGUE.LEAVE, data)
    },
    createPrivateLeague(leagueDetails) {
        return service.post(ENDPOINTS.LEAGUE.CREATE_PRIVATE, leagueDetails)
    },
    registerUserInLeague(registrationDetails) {
        return service.post(ENDPOINTS.LEAGUE.REGISTER_USER, registrationDetails)
    },
    fetchParticipantsForLeague(leagueId) {
        return service.get(ENDPOINTS.LEAGUE.PARTICIPANTS, {
            params: {
                leagueId: leagueId,
            },
        })
    },
    fetchLeagueState(leagueId) {
        return service.get(ENDPOINTS.LEAGUE.STATE, {
            params: {
                leagueId: leagueId,
            },
        })
    },
    fetchLeagueStateForGameweek(leagueId, gameweek) {
        return service.get(ENDPOINTS.LEAGUE.GAMEWEEK, {
            params: {
                leagueId: leagueId,
                gameweek: gameweek
            },
        })
    },
    fetchLeaguesSnapshot(userId) {
        return service.get(ENDPOINTS.LEAGUE.SNAPSHOT, {
            params: {
                userId: userId,
            },
        })
    },
    fetchGameweekSnapshot(userId, gameweek, sport) {
        return service.get(ENDPOINTS.LEAGUE.GAMEWEEK_SNAPSHOT, {
            params: {
                userId: userId,
                gameweek: gameweek,
                sport: sport
            },
        })
    },
    fetchTeamTotals(year) {
        return service.get(ENDPOINTS.STATISTICS.TEAM_TOTAL_SCORE, {
            params: {
                year: year
            }
        })
    },
    fetchPlayerTotals(team, year) {
        return service.get(ENDPOINTS.STATISTICS.PLAYER_TOTAL_SCORE, {
            params: {
                team: team,
                year: year
            }
        })
    },
    fetchTeamScorers(team) {
        return service.get(ENDPOINTS.STATISTICS.TEAM_SCORERS, {
            params: {
                team: team
            }
        })
    },
    searchByUsername(searchString) {
        return service.get(ENDPOINTS.USERS.SEARCH_BY_USERNAME, {
            params: {
                searchString: searchString
            }
        })
    },
    searchByLeagueName(searchString) {
        return service.get(ENDPOINTS.LEAGUE.SEARCH_BY_LEAGUE_NAME, {
            params: {
                searchString: searchString
            }
        })
    },
    existsByLeagueName(name) {
        return service.get(ENDPOINTS.LEAGUE.EXISTS_BY_LEAGUE_NAME, {
            params: {
                name: name
            }
        })
    },
    findWinnersByCompetition(sport) {
        return service.get(ENDPOINTS.WINNERS.LIST, {
            params: {
                sport: sport
            }
        })
    },
    findWinnersByTeam(teamId) {
        return service.get(ENDPOINTS.WINNERS.LIST_FOR_TEAM, {
            params: {
                teamId: teamId
            }
        })
    },
    findWinnersBySport(sport) {
        return service.get(ENDPOINTS.WINNERS.LIST_BY_TEAM, {
            params: {
                sport: sport
            }
        })
    }
}