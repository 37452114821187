import Config from '@/api/config.js'

const state = {
    fixtures: [],
    inPlayFixtures: [],
    currentFixtures: [],
    teamFixtures: [],
    competitions: []
};

const getters = {
    getTeamFixtures: state => state.teamFixtures,
    getFixtures: state => { 
      return state.fixtures.map(fixture => ({ 
        id: fixture.id,
        homeTeam: fixture.homeTeam ? fixture.homeTeam.name : 'TBD',
        homeTeamId: fixture.homeTeam ? fixture.homeTeam.id : null,
        homeLogo: fixture.homeTeam ? fixture.homeTeam.code : '',
        homeColour: fixture.homeTeam ? fixture.homeTeam.colour: '',
        awayTeam: fixture.awayTeam ? fixture.awayTeam.name : 'TBD',
        awayTeamId: fixture.awayTeam ? fixture.awayTeam.id : null,
        awayLogo: fixture.awayTeam ? fixture.awayTeam.code : '',
        awayColour: fixture.awayTeam ? fixture.awayTeam.colour : '',
        date: new Date(fixture.date).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }),
        time: new Date(fixture.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        fullDate: fixture.date,
        venue: fixture.venue,
        stage: fixture.stage,
        round: fixture.round,
        competition: fixture.competitionEnt.name,
        competitionId: fixture.competitionEnt.Id,
        matchday: fixture.matchday,
        matchResult: fixture.matchResult,
        homePoints: fixture.homePoints,
        homeGoals: fixture.homeGoals,
        awayPoints: fixture.awayPoints,
        awayGoals: fixture.awayGoals,
        coverage: fixture.coverage,
        matchdayLabel: fixture.matchdayLabel,
        inPlay: fixture.inPlay
      })).sort((a, b) => new Date(b.date) - new Date(b.date));
    },
    getInPlayFixtures: state => { 
      if(!state.inPlayFixtures) return {};
      return state.inPlayFixtures.map(fixture => ({ 
        id: fixture.id,
        homeTeam: fixture.homeTeam.name,
        homeTeamId: fixture.homeTeam.id,
        homeLogo: fixture.homeTeam.code,
        homeColour: fixture.homeTeam.colour,
        awayTeam: fixture.awayTeam.name,
        awayTeamId: fixture.awayTeam.id,
        awayLogo: fixture.awayTeam.code,
        awayColour: fixture.awayTeam.colour,
        date: new Date(fixture.date).toLocaleDateString(),
        time: new Date(fixture.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        venue: fixture.venue,
        stage: fixture.stage,
        competition: fixture.competitionEnt.name,
        matchday: fixture.matchday
      })).sort((a, b) => new Date(a.date) - new Date(b.date));
    },
    getCurrentFixtures: state => { 
      return state.currentFixtures.map(fixture => ({ 
        id: fixture.id,
        homeTeam: fixture.homeTeam.name,
        homeLogo: fixture.homeTeam.code,
        homeColour: fixture.homeTeam.colour,
        awayTeam: fixture.awayTeam.name,
        awayLogo: fixture.awayTeam.code,
        awayColour: fixture.awayTeam.colour,
        date: new Date(fixture.date).toLocaleDateString(),
        time: new Date(fixture.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        venue: fixture.venue,
        stage: fixture.stage,
        competition: fixture.competitionEnt.name,
        matchday: fixture.matchday
      })).sort((a, b) => new Date(a.date) - new Date(b.date));
    }
}

const mutations = {
    setCompetitions(state, competitionData){
        state.competitions = competitionData;
    },
    setFixtures(state, fixtures){
      state.fixtures = fixtures;
    },
    setInPlayFixtures(state, fixtures){
      state.inPlayFixtures = fixtures;
    },
    setCurrentFixtures(state, fixtures){
      state.currentFixtures = fixtures;
    },
    setFixturesForTeam(state, fixtures){
      state.teamFixtures = fixtures;
    }
};

const actions = {
  async createFixture({ dispatch }, fixtureData) {
    let combinedDateTime = fixtureData.date + 'T' + fixtureData.time + ':00';
    try {
      let fixture = {
        id: fixtureData.id,
        competition: fixtureData.competition.id,
        homeTeam: fixtureData.homeTeam.id ? fixtureData.homeTeam.id : fixtureData.homeTeamId,
        awayTeam: fixtureData.awayTeam.id ? fixtureData.awayTeam.id : fixtureData.awayTeamId,
        venue: fixtureData.venue,
        date: new Date(combinedDateTime).toISOString(),
        matchday: fixtureData.matchday,
        stage: fixtureData.stage,
        inPlay: fixtureData.inPlay,
        time: fixtureData.time,
        coverage: fixtureData.coverage,
        round: fixtureData.round,
        matchdayLabel: fixtureData.matchdayLabel
      }
      const response = await Config.createFixture(fixture);
      dispatch('fetchFixtures', fixtureData.competition.id);
       // Make API call
      return response.data;
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchInPlayFixtures({ commit }, competitionId) {
    try {
      const response = await Config.fetchInPlayFixtures(competitionId); // Make API call
      commit('setInPlayFixtures', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchCurrentFixtures({ commit }, competitionId) {
    try {
      const response = await Config.fetchCurrentFixtures(competitionId); // Make API call
      commit('setCurrentFixtures', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchFixturesSnapshot({ commit }, competitionId) {
    try {
      const response = await Config.fetchFixtures(competitionId, true); // Make API call
      commit('setFixtures', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchFixtures({ commit }, competitionId) {
    try {
      const response = await Config.fetchFixtures(competitionId, false); // Make API call
      commit('setFixtures', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchFixturesForTeam({ commit }, data) {
    try {
      const response = await Config.fetchFixturesForTeam(data.competitionId, data.teamId); // Make API call
      commit('setFixturesForTeam', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async updateFixture({ commit }, fixtureData) {
    try {
      return await Config.updateFixture(fixtureData); // Make API call
    } catch (error) {
      console.error('Error updating fixture:', error);
    }  
  },
  async endGameWeek({ commit }, gameWeekData) {
    try {
      const response = await Config.endGameWeek(gameWeekData); 
      return true;
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error ending gameweek:', error);
    }
  },
  async startGameWeek({ commit }, gameWeekData) {
    try {
      const response = await Config.startGameWeek(gameWeekData); 
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error starting gameweek:', error);
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
