import Config from '@/api/config.js'

const state = {
    selection: [],
    winningMargins: [],
    emailRecipients: [],
    gameWeekSelection: [],
    gameWeekResult: {},
    pastSelections: []
};

const getters = {
    getSelection: state => { return state.selection },
    getWinningMargins: state => { return state.winningMargins },
    getEmailRecipients: state => { return state.emailRecipients },
    hasMadeSelection: state => { return state.gameWeekSelection.length > 0 },
    getGameWeekSelection: state => { return state.gameWeekSelection },
    getGameweekResult: state => { return state.gameWeekResult },
    getPastSelections: state => { return state.pastSelections }
}

const mutations = {
    addSelection(state, selectionData){
        const index = state.selection.findIndex(item => item.fixtureId === selectionData.fixtureId);
  
        if (index !== -1) {
          state.selection[index] = selectionData;
        } else {
          state.selection.push(selectionData);
        }
    },
    setWinningMargins(state, winningMargins){
      state.winningMargins = winningMargins;
    },    
    setEmailRecipients(state, emailRecipients){
      state.emailRecipients = emailRecipients;
    },
    setGameWeekSelection(state, data){
      state.gameWeekSelection = data;
    },
    setGameWeekResult(state, data){
      state.gameWeekResult = data;
    },
    setPastSelections(state, data){
      state.pastSelections = data;
    }
};

const actions = {
  async makeSelection({ commit }, selectionData) {
    try {
      commit('addSelection', selectionData);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async confirmSelection({commit, rootState}) {
    try {
      const response = await Config.confirmSelection({"predictions": state.selection, "userId": rootState.authData.loggedInUser.userDetails.id});
      commit('setGameWeekSelection', [{}]);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchWinningMargins({ commit }, sport){
    try {
      const response = await Config.fetchWinningMargins(sport);
      commit('setWinningMargins', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error fetching winning margins:', error);
    }
  },
  async fetchEmailRecipients({ commit }){
    try {
      const response = await Config.fetchEmailRecipients();
      commit('setEmailRecipients', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error fetching email recipients:', error);
    }
  },
  async fetchGameWeekSelection({ commit }, data){
    try {
      const response = await Config.fetchGameWeekSelection(data.userId, data.gameweek, data.competitionId);
      commit('setGameWeekSelection', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchGameweekResultForUser({ commit }, data){
    try {
      const response = await Config.fetchGameweekResultForUser(data.userId, data.sport, data.gameweek);
      commit('setGameWeekResult', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchPastSelections({ commit }, data){
    try {
      const response = await Config.fetchPastSelections(data.userId, data.competitionId);
      commit('setPastSelections', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  clearPastSelections({ commit }){
    commit('setPastSelections', []);
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
