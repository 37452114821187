// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue";
import Vuetify from "vuetify/lib";
import { themePreset } from "../../src/themeConfig";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: themePreset.theme,
  rtl: themePreset.rtl
});

// Function to dynamically update the 'club' theme
export function setClubTheme(colors) {
  vuetify.framework.theme.themes.club = {
    primary: colors.primary || "#00783e",
    secondary: colors.secondary || "#00c666",
    success: colors.success || "#4CAF50",
    danger: colors.danger || "#F44336",
    warning: colors.warning || "#FF9800",
    info: colors.info || "#2196F3"
  };
}

// Example: Set an initial club theme dynamically
setClubTheme({
  primary: "#123456",
  secondary: "#654321"
});
vuetify.framework.theme.themes.light2 = themePreset.theme.themes.light2;

export default vuetify;