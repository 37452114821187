import Config from '@/api/config.js'

const state = {
    teams: [],
    team: {},
    codes: new Map([
      ["ANTRIM", "ANT"],
      ["ARMAGH", "ARM"],
      ["CARLOW", "CAR"],
      ["CAVAN", "CAV"],
      ["CLARE", "CLA"],
      ["CORK", "CORK"],
      ["DERRY", "DER"],
      ["DONEGAL", "DON"],
      ["DOWN", "DOW"],
      ["DUBLIN", "DUB"],
      ["FERMANAGH", "FER"],
      ["GALWAY", "GAL"],
      ["KERRY", "KER"],
      ["KILDARE", "KIL"],
      ["KILKENNY", "KK"],
      ["LAOIS", "LAO"],
      ["LEITRIM", "LEI"],
      ["LIMERICK", "LIM"],
      ["LONGFORD", "LON"],
      ["LOUTH", "LOU"],
      ["MAYO", "MAY"],
      ["MEATH", "MEA"],
      ["MONAGHAN", "MON"],
      ["OFFALY", "OFF"],
      ["ROSCOMMON", "ROS"],
      ["SLIGO", "SLI"],
      ["TIPPERARY", "TIPP"],
      ["TYRONE", "TYR"],
      ["WATERFORD", "WAT"],
      ["WESTMEATH", "WEM"],
      ["WEXFORD", "WEX"],
      ["WICKLOW", "WIC"],
      ["LONDON", "LOND"],
      ["NEW YORK", "NY"],
      ["GLASGOW", "GLA"],
      ["WARWICKSHIRE", "WAR"],
      ["LANCASHIRE", "LANC"],
      ["FINGAL", "FGL"],
      ["SOUTH DOWN", "SDN"]
    ])      
};

const getters = {
    getTeams: state => { return state.teams.sort((a, b) => a.id - b.id) },
    getTeam: state => state.team,
    getTeamCodes: state => state.codes,
    getMunsterHurlingTeams: state => {
      const teams = ['CLA', 'CORK', 'LIM', 'TIPP', 'WAT']
      return state.teams.filter(it => teams.indexOf(it.code) > -1)
    },
    getTeamsHurlingSort: state => { 
      const prioritizeList = ['CLA', 'CORK', 'LIM', 'TIPP', 'WAT', 'DUB', 'GAL', 'KK', 'WEX', 'OFF', 'ANT', 'LAO', 'CAR'];
      return state.teams.sort((a, b) => {
        const indexA = prioritizeList.indexOf(a.code);
        const indexB = prioritizeList.indexOf(b.code);
        
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        } else if (indexA !== -1) {
          return -1;
        } else if (indexB !== -1) {
          return 1;
        } else {
          return a.id - b.id;
        }
      });
    },
    getTeamsFootballSort: state => { 
      const prioritizeList = ['DER', 'CORK', 'KER', 'DUB', 'GAL'];
      return state.teams.sort((a, b) => {
        const indexA = prioritizeList.indexOf(a.code);
        const indexB = prioritizeList.indexOf(b.code);
        
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        } else if (indexA !== -1) {
          return -1;
        } else if (indexB !== -1) {
          return 1;
        } else {
          return a.id - b.id;
        }
      });
    }
}

const mutations = {
    setTeamList(state, teamData){
        state.teams = teamData;
    },
    setTeam(state, teamData){
      state.team = teamData;
    }
};

const actions = {
  async createTeam({ commit }, teamData) {
    try {
      const response = await Config.createTeam(teamData); // Make API call
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating team:', error);
    }
  },
  async fetchTeams({ commit }, sport) {
    try {
        const response = await Config.fetchTeams(sport); // Make API call
        commit('setTeamList', response.data);
      } catch (error) {
        console.error('Error creating team:', error);
      }
  },
  async fetchTeam({ commit }, id) {
    try {
        const response = await Config.fetchTeamById(id); // Make API call
        commit('setTeam', response.data);
      } catch (error) {
        console.error('Error creating team:', error);
      }
  },
  async fetchTeamsByCompetition({ commit }, competitionId) {
    try {
        const response = await Config.fetchTeamsByCompetition(competitionId); // Make API call
        commit('setTeamList', response.data);
      } catch (error) {
        console.error('Error creating team:', error);
      }
  },
  async fetchTeamsBySport({ commit }, sport) {
    try {
      const response = await Config.fetchTeamsBySport(sport); // Make API call
      commit('setTeamList', response.data);
    } catch (error) {
      console.error('Error creating team:', error);
    }
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
