import axios from 'axios'
import router from '@/router'
import ENDPOINTS from '@/api/endpoints/ktg-services.js'
import store from '@/store'

const UNAUTHORIZED = 401

const baseURL = process.env.VUE_APP_API_URL;

const newAxios = function () {
  return axios.create({
    baseURL: baseURL,
  })
}

export const service = newAxios()
export const socketURL = baseURL

const requestQueue = []
let reauthorising = false

// Set the AUTH token for any request
service.interceptors.request.use(function (config) {
  const token = JSON.parse(localStorage.getItem('token'))
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

service.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config
    const { status } = error.response
    if (error.response?.status !== 401) {
      return Promise.reject(error)
    }
    if (status === UNAUTHORIZED) {
      if (!reauthorising) {
        reauthorising = true
        return service.post(ENDPOINTS.AUTH.REFRESH_TOKEN, { refreshToken: store.getters.getRefreshToken })
        .then(response => {
          if (response.data.accessToken) {
            reauthorising = false
            localStorage.setItem('token', JSON.stringify(response.data.accessToken))
            originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`
            return axios(originalRequest)
          } else {
            reauthorising = false
            localStorage.removeItem('user')
            router.push('/login')
          }
          requestQueue.push(error.config)
        })
      } else {
        reauthorising = false
        localStorage.removeItem('user')
        router.push('/login')
      }
    }
  },
)
